import { CurriculumComponent } from './components/curriculum/curriculum.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { AllCurriculumsComponent } from "./components/all-curriculums/all-curriculums.component";
import { HexGridComponent } from "./components/hex-grid/hex-grid.component";
import { DetailPageComponent } from "./components/detail-page/detail-page.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {AllCompetenciesComponent} from "./components/all-competencies/all-competencies.component";

const routes: Routes = [
  {
    path: "curriculum-detail/:name",
    component: DetailPageComponent
  },
  {
    path: "home",
    component: AllCurriculumsComponent
  },
   {
    path: "all-competencies",
    component: AllCompetenciesComponent,
  },
  {
    path: "homepage",
    component: HomepageComponent
  },
  {
    path: "curriculum/:name",
    component: CurriculumComponent
  },
  {
    path: "",
    redirectTo: "/homepage",
    pathMatch: "full"
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}]
})
export class AppRoutingModule {}
