import { DataProvider } from "./../../shared/services/dataProvider";
import { HexaInfo } from "src/app/shared/interfaces";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "all-curriculums",
  templateUrl: "./all-curriculums.component.html",
  styleUrls: ["./all-curriculums.component.scss"]
})
export class AllCurriculumsComponent implements OnInit {
  constructor(private dataProvider: DataProvider) {}
  logoAnimationDelay = "0s";
  firstRow: HexaInfo[] = [
    {
      text: "",
      colored: true,
      colorValue: "#FFFFFF",
      borderColor: "#FFFFFF",
      innerHexaColorValue: "#d7e7ef"
    },
    {
      text: "",
      colored: false,
      colorValue: "#FFFFFF",
      borderColor: "#FFFFFF"
    },
    {
      text: "",
      colored: true,
      colorValue: "#FFFFFF",
      borderColor: "#FFFFFF",
      innerHexaColorValue: "#d7e7ef"
    },
    {
      text: "",
      colored: false,
      colorValue: "#FFFFFF",
      borderColor: "#FFFFFF"
    }
  ];
  allItems: HexaInfo[][];

  ngOnInit() {
    // this.allItems = this.dataProvider.getAllNonAnimatedItems();
    if (this.dataProvider.getAnimateCurriculumStatus())
      this.logoAnimationDelay = this.dataProvider.getLogoAnimationDelay() + "s";
    console.log(this.logoAnimationDelay);
    this.allItems = this.dataProvider.getAllItems();
  }
  startNextData() {
    this.allItems = this.dataProvider.getAllItems();
  }
}
