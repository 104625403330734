import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  item = {
    text: "Role Models For the World",

    colored: false,
    imgSrc: "../../../assets/images/RoleModels.gif",
    colorValue: "#00586f",
    otherSideTextColor: "#d7e7ef",
    otherTextColor: "#00586f",
    borderColor: "#6790a1",
    doNotAnimate: true,
    specialDataTypes: ["img", "img", "img"],
    specialDataValues: [
      "assets/images/globefull1.png",
      "assets/images/text.png",
      "assets/images/persons.gif"
    ],
    specialDataAnimations: ["spin", "", ""],
    specialDataHeight: ["35%", "25%", "40%"],
    animateDelay: 0.75 * 0,
  }
  constructor(private router: Router) {}



  ngOnInit() {
  }

  navigateToAllCompetencies(){
    this.router.navigate(['all-competencies']);
  }

  goToFRC() {
    $('<a href="/frc">')[0].click();
  }

  goToGraphicRsources() {
    $('<a href="https://docs.google.com/presentation/d/1faCPmGjwwAXI3AV_xVUZmdtdTBwNka_VtBg-rjRPagE/edit?usp=sharing">')[0].click();
  }

}
