import { HexaInfo } from "src/app/shared/interfaces";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "central-hexa-detail",
  templateUrl: "./central-hexa-detail.component.html",
  styleUrls: ["./central-hexa-detail.component.scss"]
})
export class CentralHexaDetailComponent implements OnInit {
  @Input() infoObj: HexaInfo;
  @Input() textOnSide: boolean;
  @Input() animate: boolean = false;
  constructor() {}

  ngOnInit() {
    console.log(this.infoObj);
  }
  onNavigate() {
    if (this.infoObj.textLink) window.open(this.infoObj.textLink, "_blank");
  }
}
