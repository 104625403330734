import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseHexaComponent } from './components/base-hexa/base-hexa.component';
import { HexGridComponent } from './components/hex-grid/hex-grid.component';
import { BaseHexaColoredComponent } from './components/base-hexa-colored/base-hexa-colored.component';
import { SideTextComponent } from './components/side-text/side-text.component';
import { DetailHexaComponent } from './components/detail-hexa/detail-hexa.component';
import { DetailPageComponent } from './components/detail-page/detail-page.component';
import { ShiftedHexaPairComponent } from './components/shifted-hexa-pair/shifted-hexa-pair.component';
import { DetailHexaShiftComponent } from './components/detail-hexa-shift/detail-hexa-shift.component';
import { SideTextDetailComponent } from './components/side-text-detail/side-text-detail.component';
import { DivShapeComponent } from './components/div-shape/div-shape.component';
import { AllCurriculumsComponent } from './components/all-curriculums/all-curriculums.component';
import { CentralHexaComponent } from './components/central-hexa/central-hexa.component';
import { CentralHexaDetailComponent } from './components/central-hexa-detail/central-hexa-detail.component';
import { AllCompetenciesComponent } from './components/all-competencies/all-competencies.component';


declare var $: any;
import { HomepageComponent } from './components/homepage/homepage.component';
import { CurriculumComponent } from './components/curriculum/curriculum.component';

@NgModule({
  declarations: [
    AppComponent,
    BaseHexaComponent,
    HexGridComponent,
    BaseHexaColoredComponent,
    SideTextComponent,
    DetailHexaComponent,
    DetailPageComponent,
    ShiftedHexaPairComponent,
    DetailHexaShiftComponent,
    SideTextDetailComponent,
    DivShapeComponent,
    AllCurriculumsComponent,
    CentralHexaComponent,
    CentralHexaDetailComponent,
    AllCompetenciesComponent,
    CentralHexaDetailComponent,
    HomepageComponent,
    CurriculumComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
