import { DataProvider } from "./../../shared/services/dataProvider";
import { Component, OnInit, Input } from "@angular/core";
import { HexaInfo } from "src/app/shared/interfaces";
import { Router } from "@angular/router";

@Component({
  selector: "hex-grid",
  templateUrl: "./hex-grid.component.html",
  styleUrls: ["./hex-grid.component.scss"]
})
export class HexGridComponent implements OnInit {
  constructor(private router: Router, private dataProvider: DataProvider) {}
  @Input() firstRow: HexaInfo[];
  @Input() allItems: HexaInfo[][];

  ngOnInit() {}
  hexaItemClicked(name: string) {
    this.router.navigate(["/curriculum-detail/" + name.toLowerCase().trim()]);
  }
}
