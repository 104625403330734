import {
  SideTextDetail,
  AllCurriculumSideText,
  AnimationDuration
} from "./../interfaces";
import { Injectable } from "@angular/core";
import { HexaInfo } from "../interfaces";

@Injectable({
  providedIn: "root"
})
export class DataProvider {
  private animationIntervalMainPage = 0.35;
  private animationDelayMainPage = 0.75;
  private baseHexaData = {
    text: "",
    colored: false,
    colorValue: "#FFFFFF",
    borderColor: "#FFFFFF"
  };
  private animateCurriculmPage: boolean = true;
  // private logoAnimationDelay = 7 * 0.35 + 0.75;
  private logoAnimationDelay = 0;
  private animationDetailPage: Map<String, boolean> = new Map([
    ["academic", true],
    ["metacognition", true],
    ["service", true],
    ["enterprise", true],
    ["digital", true],
    ["wellbeing", true],
    ["role models for the world", true]
  ]);
  private animationTime: AnimationDuration = {
    leftPolygonDuration: 1.5,
    rightPoylgonDuration: 1.95
  };
  private allCurriculumSideText: AllCurriculumSideText = {
    headText: ["Future Ready", "Curriculum", "Dashboard"],
    headTextAlignment: ["left", "right", "right"],
    paraText:
      " Welcome to Future Ready Curriculum Dashboard. From here, you will be able to access FRC information, documents and updates.",
    buttonText: "Click on hexagon to start",
    listHeading: "Other useful documents:",
    listItems: [
      {
        text: "Working group lists",
        textLink:
          "https://docs.google.com/spreadsheets/d/1wVaGFla4WTvMGudmrpDsXEHYieQ0Kd2zzGbo-qoOVdY/edit#gid=0"
      },
      {
        text: "Poster Graphic",
        textLink:
          "https://drive.google.com/file/d/1LV5x4hU19eN3nrNmvVvp286-df54SgDa/view?usp=sharing"
      },
      {
        text: "Road Map",
        textLink:
          "https://docs.google.com/presentation/d/1jAWqBBJNeBdJsklwsnnIUBtDk4W9GGjdplSCRRG_qQs/preview"
      }
    ]
  };
  private allItems: HexaInfo[][] = [
    [
      {
        text: "",
        colored: false,
        colorValue: "#FFFFFF",
        borderColor: "#FFFFFF"
      },
      {
        text: "Academic",
        colored: false,
        colorValue: "#e8539e",
        borderColor: "#c04283",
        animateDelay:
          1 * this.animationIntervalMainPage + this.animationDelayMainPage
      },
      {
        text: "Metacognition",
        colored: false,
        colorValue: "#faa431",
        borderColor: "#c78329",
        animateDelay:
          2 * this.animationIntervalMainPage + this.animationDelayMainPage
      },
      {
        text: "",
        colored: false,
        colorValue: "#FFFFFF",
        borderColor: "#FFFFFF"
      }
    ],
    [
      {
        text: "",
        colored: true,
        colorValue: "#FFFFFF",
        borderColor: "#FFFFFF",
        innerHexaColorValue: "#d7e7ef"
      },
      {
        text: "Wellbeing",
        colored: false,
        colorValue: "#70ab43",
        borderColor: "#669d3d",
        animateDelay:
          6 * this.animationIntervalMainPage + this.animationDelayMainPage
      },
      {
        text: "Role Models For the World",

        colored: false,
        imgSrc: "../../../assets/images/RoleModels.gif",
        colorValue: "#00586f",
        otherSideTextColor: "#d7e7ef",
        otherTextColor: "#00586f",
        borderColor: "#6790a1",
        doNotAnimate: true,
        specialDataTypes: ["img", "img", "img"],
        specialDataValues: [
          "assets/images/globefull1.png",
          "assets/images/text.png",
          "assets/images/persons.gif"
        ],
        specialDataAnimations: ["spin", "", ""],
        specialDataHeight: ["35%", "25%", "40%"],
        animateDelay: this.animationDelayMainPage * 0
      },
      {
        text: "Service",
        colored: false,
        colorValue: "#359fb7",
        borderColor: "#298094",
        animateDelay:
          3 * this.animationIntervalMainPage + this.animationDelayMainPage
      }
    ],
    [
      {
        text: "",
        colored: false,
        colorValue: "#FFFFFF",
        borderColor: "#FFFFFF"
      },
      {
        text: "Digital",
        colored: false,
        colorValue: "#1d4494",
        borderColor: "#1b418f",
        animateDelay:
          5 * this.animationIntervalMainPage + this.animationDelayMainPage
      },
      {
        text: "Enterprise",
        colored: false,
        colorValue: "#664fa1",
        borderColor: "#4c3a74",
        animateDelay:
          4 * this.animationIntervalMainPage + this.animationDelayMainPage
      },
      {
        text: "",
        colored: false,
        colorValue: "#FFFFFF",
        borderColor: "#FFFFFF"
      }
    ]
  ];
  private sideTextContent: SideTextDetail[] = [
    {
      mainText: "Role Models for the world",
      multiLineHeadText: ["Role Models", "For the World"],
      paraText:
        "St Christopher’s students will receive a well-defined, challenging academic curriculum while also being empowered to be Global Citizens and Autonomous Learners, equipped with the competencies to engage with and act in the world. Explore each of our curriculum elements of our Future Ready Curriculum",
      listHeading: "",
      listItems: [],
      buttonText: ["Home", "Academic"],
      rightHexaText: ["School   Vision", "School Values"],
      leftHexaText: [
        {
          text: "Intent Statement",
          textLink: ""
        },
        {
          text: "Research",
          textLink: ""
        },
        {
          text: "Competencies",
          textLink: ""
        },
        {
          text: "Sharing Good Practice",
          textLink: ""
        }
      ]
    },
    {
      mainText: "Academic",
      paraText:
        "The Academic programme provides learners with a broad, stimulating and balanced range of disciplines in order to build depth in subject knowledge, skills and understanding.",
      listHeading: "This includes:",
      listItems: [
        "Subject-rich learning experiences to promote depth of knowledge",
        "High-expectations with appropriate challenge, guidance and support",
        "Independence of mind through self-identity and autonomous learning"
      ],
      buttonText: ["Home", "Metacognition"],
      rightHexaText: ["Subjects", "Intellect"],
      leftHexaText: [
        {
          text: "Intent Statement",
          textLink:
            "https://docs.google.com/document/d/1VPo9uucJUjCZmiNwZIGr2YK1Pdn39FhmyJr0pw4J4Zk/edit?usp=sharing"
        },
        {
          text: "Research",
          textLink:
            "https://drive.google.com/drive/folders/1wUWpRvvHzTtXXVQYpuO7no8kPIzGWvRu?usp=sharing"
        },
        {
          text: "Competencies",
          textLink:
            "https://docs.google.com/document/d/1gMbmdoXC9vQr2T-AYodEpfC7WEks13aoTZBYlM05zkc/edit?usp=sharing "
        },
        {
          text: "Sharing Good Practice",
          textLink: ""
        }
      ]
    },
    {
      mainText: "Metacognition",
      paraText:
        "Metacognition for mastery! Metacognition aims to equip learners with knowledge, skills and understanding so that they can identify the process of learning and be able to apply cognitive approaches to improve their own performance.",
      listHeading: "This includes:",
      listItems: [
        "Transitioning from a novice to an expert learner",
        "Develop positive mindset behaviours that enhance learning",

        "Developing skills and attitudes to become a lifelong learner"
      ],
      buttonText: ["Home", "Service"],
      rightHexaText: ["Learning to Learn", "Mindset"],
      leftHexaText: [
        {
          text: "Intent Statement",
          textLink:
            "https://docs.google.com/document/d/1n3RvcRWV-o1MWQHOmFh-sc8idEAw-MWlor2wsg0_XyA/edit?usp=sharing"
        },
        {
          text: "Research",
          textLink:
            "https://drive.google.com/drive/folders/1-XfYKcRTh9cnfR9Cfg5BUWwK5xBx3Dg6?usp=sharing"
        },
        {
          text: "Competencies",
          textLink:
            "https://docs.google.com/document/d/1X2hJBY1J2NsbW1hPs8GiJoxzyYPF8t9aS6cuxkFTgms/edit?usp=sharing "
        },
        {
          text: "Sharing Good Practice",
          textLink: ""
        }
      ]
    },
    {
      mainText: "Service",
      paraText:
        "Service for a sustainable future! Service aims to purposefully engage learners to contribute directly to the environmental, economic and social challenges within Bahrain and the global community. ",
      listHeading: "This includes:",
      listItems: [
        "Become a responsive and responsible global citizen",
        "Develop attitudes of care and empathy for others",
        "Actively contribute to SDGs"
      ],
      buttonText: ["Home", "Enterprise"],
      rightHexaText: ["Society", "World"],
      leftHexaText: [
        {
          text: "Intent Statement",
          textLink:
            "https://docs.google.com/document/d/1KY20vYLgAYqz4cJcG6Wn_IoDppoQ_fbpvCar0MIwJ_0/edit?usp=sharing"
        },
        {
          text: "Research",
          textLink:
            "https://drive.google.com/drive/folders/1CA4RmfmuyxwdmImGIk8ABDchtTi7YdZG?usp=sharing"
        },
        {
          text: "Competencies",
          textLink:
            "https://docs.google.com/document/d/1zmnyVHSdXQagpy_s9dbpT5hIM0fwo0fYTuFCmBNTNJo/edit?usp=sharing "
        },
        {
          text: "Sharing Good Practice",
          textLink: ""
        }
      ]
    },
    {
      mainText: "Enterprise",
      paraText:
        "Enterprise to engage! Enterprise aims to equip learners with knowledge, skills and understanding so that they can recognise and successfully respond to enterprise opportunities.",
      listHeading: "This includes:",
      listItems: [
        "Use initiative to identify, define and develop solutions for the real-world",
        "Communicating ideas confidently & effectively",
        "Developing leadership capability",
        "Develop financial literacy skills"
      ],
      buttonText: ["Home", "Digital"],
      rightHexaText: ["Vocational Skills", "Future Career"],
      leftHexaText: [
        {
          text: "Intent Statement",
          textLink:
            "https://docs.google.com/document/d/1gRonOytdMywk1FYqxe-BanptmYUctcVXH-2XoNsvWDg/edit?usp=sharing"
        },
        {
          text: "Research",
          textLink:
            "https://drive.google.com/drive/folders/1e5UNeAdtLNjjUJ0pFcfV8Su8nZrptF15?usp=sharing"
        },
        {
          text: "Competencies",
          textLink:
            "https://docs.google.com/document/d/1Qvld7uxwZ9TZGbDmF8DF7v2Z3c_Pl_7gcmICRZdxiGY/edit?usp=sharing"
        },
        {
          text: "Sharing Good Practice",
          textLink: ""
        }
      ]
    },
    {
      mainText: "Digital",
      paraText:
        "Digital for digital leaders. Digital proficiency aims to equip learners with skills, knowledge and understanding so that they can participate meaningfully and safely when using technology in social, academic and economic situations.",
      listHeading: "This includes:",
      listItems: [
        "Use technology confidently and efficiently",
        "Research effectively and be critical",
        "Communicate and collaborate successfully",
        "Build a positive digital identity"
      ],
      buttonText: ["Home", "Wellbeing"],
      rightHexaText: ["Digital Leadership", "Digital Literacy"],
      leftHexaText: [
        {
          text: "Intent Statement",
          textLink:
            "https://docs.google.com/document/d/1UvYj50RH60MbzWX9A2LPFQeDc56lc_1oN7gJEl-WNXE/edit?usp=sharing"
        },
        {
          text: "Research",
          textLink:
            "https://drive.google.com/drive/folders/1l3w8bYIU47em3TkCJpK51CgKbwabgch1?usp=sharing"
        },
        {
          text: "Competencies",
          textLink:
            "https://docs.google.com/document/d/1HErEf8A_5EQf2kilOexK5m1J1p35mWCJv8IJyxISxZk/edit?usp=sharing"
        },
        {
          text: "Sharing Good Practice",
          textLink: ""
        }
      ]
    },
    {
      mainText: "Wellbeing",
      paraText:
        "Wellbeing for wellness! Wellbeing aims to improve our overall sense of health, happiness and life satisfaction, while at the same time recognising and knowing how we can support the wellbeing needs of others.",
      listHeading: "This includes:",
      listItems: [
        "Develop a culture of care and concern for others",
        "Make informed choices to manage our own wellbeing",
        "Encounter aspects of good health",
        "Experience positive relationships "
      ],
      buttonText: ["Home", "Role Models"],
      rightHexaText: ["Hollistic", "Health"],
      leftHexaText: [
        {
          text: "Intent Statement",
          textLink:
            "https://docs.google.com/document/d/1T7iHMrfSuIXTmLgF7P7KPehEdd2pOcfly3qicBirVJ4/edit?usp=sharing"
        },
        {
          text: "Research",
          textLink:
            "https://drive.google.com/drive/folders/1JJyMHE50WBah_Y-n00oXr20hcr-NN-XA?usp=sharing"
        },
        {
          text: "Competencies",
          textLink:
            "https://docs.google.com/document/d/1cIEiygI1uW34XhZymji0GUdTdkjaJMDPdW8fgf-8Xu8/edit?usp=sharing "
        },
        {
          text: "Sharing Good Practice",
          textLink: ""
        }
      ]
    }
  ];
  getAnimationTimeForDetailPage() {
    return this.animationTime;
  }
  setDetailPageAnimationStatus(name: string, status: boolean) {
    this.animationDetailPage.set(name, status);
  }
  getDetailPageAnimationStatus(name: string) {
    console.log("name is ", name);
    if (this.animationDetailPage.get(name) != null)
      return this.animationDetailPage.get(name);
    else return false;
  }
  getAllItems() {
    let temp: HexaInfo[][] = new Array();
    this.allItems.forEach(list => {
      var newArray: HexaInfo[] = new Array();
      list.forEach(el => {
        let newItem = { ...el };
        newArray.push(newItem);
      });
      temp.push(newArray);
    });
    return temp;
  }
  getLogoAnimationDelay() {
    return this.logoAnimationDelay;
  }
  setAnimateCurriculm(val: boolean) {
    this.animateCurriculmPage = val;
  }
  getAnimateCurriculumStatus() {
    return this.animateCurriculmPage;
  }
  getAllNonAnimatedItems() {
    let temp: HexaInfo[][] = new Array();
    this.allItems.forEach(list => {
      var newArray: HexaInfo[] = new Array();
      list.forEach(el => {
        let newItem: HexaInfo;
        if (el.doNotAnimate) newItem = { ...el };
        else newItem = this.baseHexaData;
        newArray.push(newItem);
      });
      temp.push(newArray);
    });
    return temp;
  }
  getAllCurriculumSideText() {
    return { ...this.allCurriculumSideText };
  }
  findHexaInfoByName(name: string) {
    let foundItem: HexaInfo = {
      text: "",
      colored: false,
      colorValue: "#e8539e",
      borderColor: "#e8539e"
    };
    let itemFound = false;
    for (let i = 0; i < this.allItems.length; i++) {
      for (let j = 0; j < this.allItems[i].length; j++) {
        if (
          this.allItems[i][j].text
            .toLowerCase()
            .trim()
            .includes(name.toLowerCase().trim())
        ) {
          foundItem = this.allItems[i][j];
          itemFound = true;
          break;
        }
      }
      if (itemFound) break;
    }
    return foundItem;
  }
  findSideTextByName(name: string) {
    let foundItem: SideTextDetail = this.sideTextContent[0];
    let itemFound = false;
    console.log("incoming text is", name);
    let temp = this.sideTextContent.find(
      el => el.mainText.toLowerCase() == name.toLowerCase()
    );
    console.log("Found is ", temp);
    if (temp) foundItem = temp;
    return foundItem;
  }
}
