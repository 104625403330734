import { DataProvider } from "./../../shared/services/dataProvider";
import { HexaInfo } from "src/app/shared/interfaces";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "central-hexa",
  templateUrl: "./central-hexa.component.html",
  styleUrls: ["./central-hexa.component.scss"]
})
export class CentralHexaComponent implements OnInit {
  @Input() infoObj: HexaInfo;
  @Input() animate = true;
  showData = true;
  constructor(private dataProvider: DataProvider) {}

  ngOnInit() {
    if (this.infoObj.animateDelay && this.animate) {
      this.showData = false;
      this.getShowDataStatus();
    }
    console.log("Hex value is ", this.infoObj.colorValue);
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async getShowDataStatus() {
    if (this.dataProvider.getAnimateCurriculumStatus())
      await this.delay(this.infoObj.animateDelay * 1000);

    this.showData = true;
  }
}
