import { DataProvider } from "./../../shared/services/dataProvider";
import { HexaInfo } from "./../../shared/interfaces";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "base-hexa",
  templateUrl: "./base-hexa.component.html",
  styleUrls: ["./base-hexa.component.scss"]
})
export class BaseHexaComponent implements OnInit {
  @Input() infoObj: HexaInfo;
  showData = true;
  constructor(private dataProvider: DataProvider) {}

  ngOnInit() {
    if (this.infoObj.animateDelay) {
      this.showData = false;
      this.getShowDataStatus();
    }
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async getShowDataStatus() {
    if (this.dataProvider.getAnimateCurriculumStatus())
      await this.delay(this.infoObj.animateDelay * 1000);

    this.showData = true;
  }
}
