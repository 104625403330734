import { DataProvider } from "./../../shared/services/dataProvider";
import { Component, OnInit, Input } from "@angular/core";
import {
  HexaInfo,
  DivInfo,
  SideTextDetail,
  AnimationDuration
} from "src/app/shared/interfaces";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "detail-page",
  templateUrl: "./detail-page.component.html",
  styleUrls: ["./detail-page.component.scss"]
})
export class DetailPageComponent implements OnInit {
  currentCurriculum: string;
  animationIntervalForRightPolygon = 0;
  animateAgain = false;
  animationsRequired = true;
  animationDelayRightBorders = 1;
  animationIntervalRightBorders = 2;
  animationDelayLeftBorders = 1;
  animationIntervalLeftBorders = 5;
  animationDelayForLeftHexa = 0;
  animationDelayForRightHexa = 0;

  animationDelays: AnimationDuration = {
    leftPolygonDuration: 0,
    rightPoylgonDuration: 0
  };
  infoObj: HexaInfo = {
    text: "WELLBEING",
    colored: false,
    colorValue: "#e8529e",
    borderColor: "#669d3d"
  };
  topDivInfo: DivInfo = {
    topBorder: true,
    bottomBorder: false,
    leftBorder: false,
    rightBorder: true
  };
  sideDivInfo: DivInfo = {
    topBorder: true,
    bottomBorder: true,
    leftBorder: false,
    rightBorder: false
  };
  bottomDivInfo: DivInfo = {
    topBorder: false,
    bottomBorder: true,
    leftBorder: false,
    rightBorder: true
  };
  sideTextContent: SideTextDetail = {
    mainText: "Academic",
    paraText:
      "The Academic programme provides learners with a broad, stimulating and balanced range of disciplines in order to build depth in subject knowledge, skills and understanding.",
    listHeading: "This includes:",
    listItems: ["Bullet 1", "Bullet 2", "Bullet 3", "Bullet 4"],
    buttonText: ["Home", "Next"],
    rightHexaText: ["Subjects", "Intellect"],
    leftHexaText: [
      {
        text: "Intent Statement",
        textLink:
          "https://docs.google.com/document/d/1VPo9uucJUjCZmiNwZIGr2YK1Pdn39FhmyJr0pw4J4Zk/edit?usp=sharing"
      },
      {
        text: "Research",
        textLink:
          "https://drive.google.com/drive/folders/1wUWpRvvHzTtXXVQYpuO7no8kPIzGWvRu?usp=sharing"
      },
      {
        text: "Competencies",
        textLink:
          "https://docs.google.com/document/d/1gMbmdoXC9vQr2T-AYodEpfC7WEks13aoTZBYlM05zkc/edit?usp=sharing "
      },
      {
        text: "Sharing Good Practice",
        textLink: ""
      }
    ]
  };
  rightUpperHexa: HexaInfo;
  rightLowerHexa: HexaInfo;
  leftFirstHexa: HexaInfo;
  leftSecondHexa: HexaInfo;
  leftThirdHexa: HexaInfo;
  leftFourthHexa: HexaInfo;
  constructor(
    private dataProvider: DataProvider,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.animationDelays = this.dataProvider.getAnimationTimeForDetailPage();
    this.animationIntervalForRightPolygon =
      this.animationDelays.rightPoylgonDuration - 1;
    this.animationIntervalForRightPolygon = this.animationDelays.rightPoylgonDuration;
    this.animationDelayForRightHexa =
      this.animationDelayRightBorders + this.animationIntervalRightBorders - 1;
    this.animationDelayLeftBorders = this.animationDelayForRightHexa + 1;
    this.animationDelayForLeftHexa = this.animationDelayLeftBorders + 1;

    this.initAllHexas();
    this.dataProvider.setAnimateCurriculm(false);
    this.route.params.subscribe((params: Params) => {
      this.currentCurriculum = params["name"];
      this.processCurriculum();
    });
  }
  initAllHexas() {
    this.leftFirstHexa = { ...this.infoObj };
    this.leftSecondHexa = { ...this.infoObj };
    this.leftThirdHexa = { ...this.infoObj };
    this.leftFourthHexa = { ...this.infoObj };
    this.rightUpperHexa = { ...this.infoObj };
    this.rightLowerHexa = { ...this.infoObj };
  }
  assignValues() {
    this.leftFirstHexa.text = this.sideTextContent.leftHexaText[0].text;
    this.leftSecondHexa.text = this.sideTextContent.leftHexaText[1].text;
    this.leftThirdHexa.text = this.sideTextContent.leftHexaText[2].text;
    this.leftFourthHexa.text = this.sideTextContent.leftHexaText[3].text;

    this.leftFirstHexa.textLink = this.sideTextContent.leftHexaText[0].textLink;
    this.leftSecondHexa.textLink = this.sideTextContent.leftHexaText[1].textLink;
    this.leftThirdHexa.textLink = this.sideTextContent.leftHexaText[2].textLink;
    this.leftFourthHexa.textLink = this.sideTextContent.leftHexaText[3].textLink;

    this.rightUpperHexa.text = this.sideTextContent.rightHexaText[0];
    this.rightLowerHexa.text = this.sideTextContent.rightHexaText[1];
  }
  async processCurriculum() {
    this.animateAgain = false;
    this.animationsRequired = false;
    if (this.currentCurriculum) {
      let tempHexaInfo = this.dataProvider.findHexaInfoByName(
        this.currentCurriculum
      );
      let tempSideText = this.dataProvider.findSideTextByName(
        this.currentCurriculum
      );
      if (tempHexaInfo) this.infoObj = { ...tempHexaInfo };
      if (tempSideText) this.sideTextContent = tempSideText;
    }
    this.initAllHexas();
    this.assignValues();
    await this.delay(100);
    console.log(
      "text after trimming is ",
      this.infoObj.text.toLowerCase().trim()
    );
    this.animationsRequired = this.dataProvider.getDetailPageAnimationStatus(
      this.infoObj.text.toLowerCase().trim()
    );

    this.animateAgain = true;
    if (this.animationsRequired)
      await this.delay(
        this.dataProvider.getAnimationTimeForDetailPage().rightPoylgonDuration *
          1000
      );

    this.dataProvider.setDetailPageAnimationStatus(
      this.infoObj.text.toLowerCase().trim(),
      false
    );
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
