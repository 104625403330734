import { DataProvider } from "./../../shared/services/dataProvider";
import { Component, OnInit, Input } from "@angular/core";
import { SideTextDetail } from "src/app/shared/interfaces";
import { Router } from "@angular/router";

@Component({
  selector: "side-text-detail",
  templateUrl: "./side-text-detail.component.html",
  styleUrls: ["./side-text-detail.component.scss"]
})
export class SideTextDetailComponent implements OnInit {
  @Input() sideContent: SideTextDetail;
  @Input() backGroundColor: string;
  buttonPrevColor: string = "#e8539e";
  buttonNextColor: string = "#e8539e";
  backColor: string = "#FFFFFF";
  constructor(private dataProvider: DataProvider, private router: Router) {}

  ngOnInit() {
    if (this.backGroundColor != null) this.backColor = this.backGroundColor;
    if (this.sideContent.buttonText[0]) {
      let tempPrev = this.dataProvider.findHexaInfoByName(
        this.sideContent.mainText
      );
      if (tempPrev) this.buttonPrevColor = tempPrev.colorValue;
    }

    if (this.sideContent.buttonText[1]) {
      let tempNext = this.dataProvider.findHexaInfoByName(
        this.sideContent.buttonText[1]
      );
      if (tempNext) this.buttonNextColor = tempNext.colorValue;
    }
  }
  navigateToNewPage(name: string) {
    if (name.toLowerCase() == "home") this.router.navigate(["/home"]);
    else this.router.navigate(["/curriculum-detail/" + name]);
  }
  getBackgroundColor(name: string, type?: string) {
    let tempPrev = this.dataProvider.findHexaInfoByName(name);
    if (tempPrev) {
      if (tempPrev.otherSideTextColor && !type)
        return tempPrev.otherSideTextColor;
      else return tempPrev.colorValue;
    }
    return "#e8539e";
  }
  getTextColor(name: string) {
    let tempPrev = this.dataProvider.findHexaInfoByName(name);
    if (tempPrev) {
      if (tempPrev.otherTextColor) return tempPrev.otherTextColor;
    }
    return "#FFFFFF";
  }
}
