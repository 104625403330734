import { Component, OnInit, Input } from "@angular/core";
import { DivInfo } from "src/app/shared/interfaces";

@Component({
  selector: "div-shape",
  templateUrl: "./div-shape.component.html",
  styleUrls: ["./div-shape.component.scss"]
})
export class DivShapeComponent implements OnInit {
  @Input() animateShapes = true;
  @Input() info: DivInfo;
  @Input() animationDelay = 4;
  constructor() {}
  makeSeconds(sec: number) {
    return sec + "s";
  }
  ngOnInit() {}
}
