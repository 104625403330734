import { Component, OnInit, Input } from "@angular/core";
import { HexaInfo } from "src/app/shared/interfaces";

@Component({
  selector: "detail-hexa",
  templateUrl: "./detail-hexa.component.html",
  styleUrls: ["./detail-hexa.component.scss"]
})
export class DetailHexaComponent implements OnInit {
  @Input() infoObj: HexaInfo;
  @Input() textOnSide: boolean;
  @Input() animate: boolean = false;
  @Input() animateBothHexa = false;
  @Input() animationDelay = 1;

  innerHexasAnimationDelay: number;
  constructor() {}
  ngOnInit() {
    this.innerHexasAnimationDelay = this.animationDelay + 0.5;
  }
  makeSeconds(sec: number) {
    if (!this.animateBothHexa) return "0s";
    return sec + "s";
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  onNavigate() {
    if (this.infoObj.textLink) window.open(this.infoObj.textLink, "_blank");
  }
}
