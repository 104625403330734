import { Component, OnInit, Input } from "@angular/core";
import { HexaInfo } from "src/app/shared/interfaces";

@Component({
  selector: "detail-hex-shift",
  templateUrl: "./detail-hexa-shift.component.html",
  styleUrls: ["./detail-hexa-shift.component.scss"]
})
export class DetailHexaShiftComponent implements OnInit {
  @Input() infoObj: HexaInfo;
  @Input() animationRequired: boolean = false;
  @Input() animationDelay = 0;
  @Input() animationDuration = 1;

  constructor() {}

  ngOnInit() {
    console.log(
      this.animationRequired,
      this.animationDelay,
      this.animationDuration
    );
  }
  makeSeconds(value: number) {
    return value + "s";
  }
}
