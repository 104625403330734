import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base-hexa-colored',
  templateUrl: './base-hexa-colored.component.html',
  styleUrls: ['./base-hexa-colored.component.scss']
})
export class BaseHexaColoredComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
