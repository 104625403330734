import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "shifted-hex-pair",
  templateUrl: "./shifted-hexa-pair.component.html",
  styleUrls: ["./shifted-hexa-pair.component.scss"]
})
export class ShiftedHexaPairComponent implements OnInit {
  @Input() flipped: boolean;
  @Input() animated: boolean = false;
  @Input() animationDelay = 2;
  @Input() animationDuration = 3;
  constructor() {}

  ngOnInit() {
    console.log(this.animated, this.animationDelay, this.animationDuration);
  }
  makeSeconds(value: number) {
    return value + "s";
  }
}
