import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {HexaInfo} from "../../shared/interfaces";
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-all-competencies',
  templateUrl: './all-competencies.component.html',
  styleUrls: ['./all-competencies.component.scss']
})
export class AllCompetenciesComponent implements OnInit {
  // infoObj: HexaInfo = {
  //   text: 'Academic',
  //   colored: false,
  //   colorValue: '#e8529e',
  //   borderColor: '#669d3d'
  // };

  // infoObj2: HexaInfo = {
  //   text: 'ACA01',
  //   colored: false,
  //   colorValue: '#e8529e',
  //   borderColor: '#669d3d'
  // };
  sections: Array<any>;
  constructor(private router: Router, private sharedService: SharedService) {
    this.sections = this.sharedService.competencies;
   }

  ngOnInit() {
  }

  expand(event) {
    event.currentTarget.closest('.competency-item').classList.add('expanded');
    event.currentTarget.closest('.competency-section').classList.add('expanded');
  }
  collapse(event) {
    event.currentTarget.closest('.competency-item').classList.remove('expanded');
    // event.currentTarget.closest('.competency-section').classList.remove('expanded');
    const expandedItems = $(event.currentTarget).closest('.competency-section').find('.competency-item.expanded').length;
    if (expandedItems === 0) {
      $(event.currentTarget).closest('.competency-section').removeClass('expanded');
    }
  }
  goToHome(){
    this.router.navigate(['homepage']);
  }
}
