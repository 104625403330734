import { DataProvider } from "./../../shared/services/dataProvider";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AllCurriculumSideText } from "src/app/shared/interfaces";

@Component({
  selector: "side-text",
  templateUrl: "./side-text.component.html",
  styleUrls: ["./side-text.component.scss"]
})
export class SideTextComponent implements OnInit {
  allCurriculmSideText: AllCurriculumSideText;
  @Output() btnClicked = new EventEmitter<boolean>();
  constructor(private dataProvider: DataProvider) {}

  ngOnInit() {
    this.allCurriculmSideText = this.dataProvider.getAllCurriculumSideText();
  }
  hexagonButtonClicked() {
    this.btnClicked.emit(true);
  }
}
