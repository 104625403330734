import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HexaInfo } from "./../../shared/interfaces";
import { ActivatedRoute } from '@angular/router';
import { SharedService } from './../../shared/services/shared.service';

@Component({
  selector: 'app-competency',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss']
})
export class CurriculumComponent implements OnInit {

  sections: Array<any>;
  competency: any;
  currentCompetency: any;
  nextCompetency: any;
  previousCompetency: any;
  nextCompetencytitle: any;
  previousCompetencytitle: any;
  pageSection: any;

  constructor(private router: Router,private sharedService: SharedService, private route: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
  };
    this.sections = this.sharedService.competencies;

  }

  ngOnInit() {
    this.currentCompetency = this.route.snapshot.paramMap.get('name');
    this.pageSection = 'competencies';
    this.competency = this.sections.find((section) => {
      return section.title.toLowerCase() == this.currentCompetency.toLowerCase();
    });
    // this.pageTitle =
    if(this.competency.id === 6){
      this.nextCompetencytitle = this.sections.find((section) => section.id == 1).title;
    }else{
      this.nextCompetencytitle = this.sections.find((section) => section.id == this.competency.id+1).title;
    }

    if(this.competency.id === 1){
      this.previousCompetencytitle = this.sections.find((section) => section.id == 6).title;
      this.nextCompetencytitle = this.sections.find((section) => section.id == 2).title;
    }else{
      this.previousCompetencytitle = this.sections.find((section) => section.id == this.competency.id-1).title;
    }

  }

  expand(event) {
    event.currentTarget.closest('.competency-item').classList.add('expanded');
    event.currentTarget.closest('.competency-section').classList.add('expanded');
  }
  collapse(event) {
    event.currentTarget.closest('.competency-item').classList.remove('expanded');
    // event.currentTarget.closest('.competency-section').classList.remove('expanded');
    const expandedItems = $(event.currentTarget).closest('.competency-section').find('.competency-item.expanded').length;
    if (expandedItems === 0) {
      $(event.currentTarget).closest('.competency-section').removeClass('expanded');
    }
  }

  goToHome(){
    this.router.navigate(['homepage']);
  }
  goToNextCompetency(id){
    let nextID = id +1 ;
    if(id == 6){
      nextID = 1;
    }


    this.nextCompetency = this.sections.find(section => {
      return section.id == nextID;
    });
    this.router.navigate(['/curriculum/' + this.nextCompetency.title.toLowerCase()])
  }
  goToPreviousCompetency(id){
    console.log(id);
    let previousID = id - 1 ;
    if(id == 1){
      previousID = 6;
    }
    console.log('previous',previousID);
    this.previousCompetency = this.sections.find(section => {
      return section.id == previousID;
    });
    console.log('previousCompetency',this.previousCompetency);
    this.router.navigate(['/curriculum/' + this.previousCompetency.title.toLowerCase()]);
  }

  changePageSection(section) {
    this.pageSection = section;
    console.log('page section now is ' + this.pageSection);
  }
  openResearchLink(link) {
    $('<a target="_blank" href="' + link + '">')[0].click();
  }

  public get pageTitle() {
    let title = '';
    switch (this.pageSection) {
      case 'intent_statement':
        title = "Intent Statement";
        break;
      case 'competencies':
        title = this.competency.title + ' Competencies';
        break;
    }
    return title;
  }

}
